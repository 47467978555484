import { useState, useEffect, useRef, useCallback } from 'react'
import productPreview from '../assets/product-preview.png'
import '../styles/App.css'
import '../styles/pages/home.css'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'bootstrap/dist/css/bootstrap.min.css';
import { SignedIn, SignedOut, UserButton,SignIn, useAuth, useUser} from "@clerk/clerk-react";

import {Link} from 'react-router-dom'
import {elements} from '../assets/ClerkStyling/homepage.js'
import DisentFileBrowser from 'disent-filebrowser'


const PREVIEW_SCALE = .45
const BACKEND_URL = 'http://localhost:8000'

function HomePage() {
  
  //-------------STATES----------------    
  const {isSignedIn} = useUser();
  const {getToken, signOut} = useAuth();  
  const {user} = useUser();
  const [token, setToken] = useState()
  const fileBrowserRef = useRef()
  const mySignoutButton = useRef() 
  const myEmailButton = useRef()
//-------------FUNCTIONS----------------

//------------HOOKS---------------------

    //-----------------USE EFFECTS----------------
  useEffect(()=>{
    // Replaces 2 of the buttons in the Clerk UI with custom buttons
    // as clerk components changes some of this will be swapped out
    const observer = new MutationObserver(() => {
      const signOutButton = document.querySelector('.cl-userButtonPopoverActionButton__signOut');
      const socialButtonDiv = document.querySelector('.cl-socialButtons');

      if (signOutButton && !mySignoutButton.current) {
        // This will be replaced in Mon aug 5
    
        // Remove existing event listeners
        const newButton = signOutButton.cloneNode(true);
        mySignoutButton.current = newButton;//
        signOutButton.parentNode.replaceChild(newButton, signOutButton);

        // Add custom event listener//
        newButton.addEventListener('click', async (event) => {
          event.preventDefault(); // Prevent default behavior
          // await fileBrowserRef.current.disconnect()
          await signOut();
        });
      }
      const socialButtonDivHasEmailButton = socialButtonDiv?.children.length == 7;
      if(socialButtonDiv && !socialButtonDivHasEmailButton){
        socialButtonDiv.style.gridTemplateColumns = 'repeat(7, 1fr)'
        const microsoftButton = document.querySelector('.cl-socialButtonsIconButton__microsoft');
        const newButton = microsoftButton.cloneNode(true);
        myEmailButton.current = newButton;//
        newButton.classList.remove('.cl-socialButtonsIconButton__microsoft', '.cl-button__microsoft');
        newButton.classList.add('customEmailButton');//
        newButton.removeChild(newButton.childNodes[0]);
        newButton.innerHTML = '&#9993;'
 
        socialButtonDiv.appendChild(newButton);
        console.log('what is my social butotn div that should have what i want', socialButtonDiv)
                  // Add an event listener to the button
        newButton.addEventListener('click', () => {
          window.location.href = '/sign-in';
        });//
    }
    });
    observer.observe(document.body, { childList: true, subtree: true });
    return () => observer.disconnect();
  },[])

  useEffect(()=>{

    if(isSignedIn){
      // token will last for 18 hours
        getToken({template:'disent'}).then((token)=>{
          setToken(token)})
      }

  },[isSignedIn, getToken])

  return (
    <div className="app">
      <Container fluid style={{maxHeight:'calc(90vh - 150px)'}}>
        <Row xxl={12} style={{marginTop:'0px'}}>
          <Col xxl={5}>
            <Row xxl={12} className='mb-3'>
              <p style={{fontFamily:'Calibri, sans-serif', fontSize:'6em', lineHeight: '0.95',fontWeight:'900', textAlign:"left"}}>
                say hello to<br></br>social coding.
              </p>
            </Row>
            <Row xxl={12}>
              <Col xxl={12}>
                <Row className='mb-4'>
                  <SignedOut>
                    <SignIn 
                        appearance={
                            {
                                elements:{
                                    ...elements
                                }
                            }
                        }/>
                  </SignedOut>
                  <SignedIn>
                    <div style={{display:'flex', alignItems:'center', gap:'25px'}}>
                      <UserButton
                        signOutCallback={()=>{console.log('hello')}}
                      />
                      <span style={{transition: 'all .35s ease-in-out'}}>{status?.connectionStatus}</span>                      
                    </div>

                    </SignedIn>                  
                </Row>
                <Row xxl={12}>
                  <p style={{textAlign:'left', fontSize:'14pt', '&:hover':{scale:1.1}}}>
                    By using CodeDrive you agree to our <Link to="/tos">license</Link> and <Link to="/privacy">privacy statement</Link>.
                    </p>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col xxl={7} >
            <Row style={{height:'100%',alignItems:'center', width: '100%'}}>
              <div className="disent-productpreview-container" style={{textAlign:'center', height:'fit-content'}}>
                <SignedOut>
                <img src={productPreview} className="disent-product-preview" alt="Product Preview" style={{width:`${2267*PREVIEW_SCALE}px`, height:`${1291*PREVIEW_SCALE}px`}}/>
                </SignedOut>
               
              </div>   
              <SignedIn>
                <div style={{height:'800px', width:'100%'}}>
                  {/* <AuthorizedFileBrowser
                    ref={fileBrowserRef}
                    token={token}
                  /> */}
                  <DisentFileBrowser
                    backend='firebase'
                    backendToken = {token}
                  />
                </div>
              </SignedIn>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default HomePage