import '../styles/App.css'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'bootstrap/dist/css/bootstrap.min.css';
import {SignIn} from "@clerk/clerk-react";
import {elements} from '../assets/ClerkStyling/signin.js';


function SignInPage() {
  
  //-------------STATES----------------

//-------------FUNCTIONS----------------


    //-----------------USE EFFECTS----------------


  return (
    <div className="signin">
      <Container fluid style={{minHeight:'90vh'}}>
        <Row xxl={12}>
          <Col className="d-flex justify-content-center" xxl={12}>
            <SignIn
                appearance={{
                    elements:{
                        ...elements
                    }
                }
            }
            />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default SignInPage
