import '../styles/App.css'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'bootstrap/dist/css/bootstrap.min.css';
import { SignUp } from "@clerk/clerk-react";



function SignUpPage() {
  
  //-------------STATES----------------

//-------------FUNCTIONS----------------


    //-----------------USE EFFECTS----------------
  return (
    <div className="signup">
      <Container fluid style={{minHeight:'90vh'}}>
        <Row xxl={12}>
          <Col className="d-flex justify-content-center" xxl={12}>
            <SignUp
                appearance={{
                    elements:{
                        rootBox:"signUpPageSignIn"
                    }
                }
            }
            />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default SignUpPage
