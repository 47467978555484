import React from "react";


const PRIVACY_POLICY = `We take your privacy seriously. This policy outlines how we collect, use, and protect your personal information.

What Information We Collect

We only collect information that helps us improve your experience. This may include your name, email address, and browsing activity on our website.

How We Use Your Information

Your information is used to personalize your experience, improve our services, and communicate with you. We never sell or share your information with third parties for marketing purposes.

How We Protect Your Information

We use industry-standard security measures to protect your personal information from unauthorized access, alteration, or disclosure.

Cookies and Tracking

We may use cookies and similar tracking technologies to enhance your experience on our website. You can adjust your browser settings to control cookies.

Third-Party Links

Our website may contain links to third-party websites. We are not responsible for the privacy practices or content of these websites.

Your Choices

You have the right to access, update, or delete your personal information. You can also opt out of receiving marketing communications from us.

Updates to this Policy

We may update this privacy policy from time to time. Any changes will be posted on this page.`;

const PrivacyPage = () => {

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm">
          <h1>Privacy Policy:</h1><br></br>
          <p>
            We take your privacy seriously. This policy outlines how we collect, use, and protect your personal information.<br/><br/><br/>
            <h4>What Information We Collect</h4><br/>
            We only collect information that helps us improve your experience. This may include your name, email address, and browsing activity on our website.<br/><br/><br/>
            <h4>How We Use Your Information</h4><br/>
            Your information is used to personalize your experience, improve our services, and communicate with you. We never sell or share your information with third parties for
            marketing purposes.<br/><br/><br/>
            <h4>How We Protect Your Information</h4><br/>
            We use industry-standard security measures to protect your personal information from unauthorized access, alteration, or disclosure.<br/><br/><br/>
            <h4>Cookies and Tracking</h4> <br/>
            We may use cookies and similar tracking technologies to enhance your experience on our website. You can adjust your browser settings to control cookies.<br/><br/><br/>
            <h4>Third-Party Links</h4><br/>
            Our website may contain links to third-party websites. We are not responsible for the privacy practices or
            content of these websites.<br/><br/><br/>
            <h4>Your Choices</h4><br/>
            You have the right to access, update, or delete your personal information. You can also opt out of receiving marketing communications from us.<br/><br/><br/>
            <h4>Updates to this Policy</h4><br/>
            We may update this privacy policy from time to time. Any changes will be posted on this page.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPage;
