export const elements = {
  main: {
    flexDirection: 'column-reverse'
  
  },
  dividerRow: {
    visibility:'hidden',
    display: 'none',
    height: '0px',
    width: '0px'
  },
  socialButtons: {
    visibility:'hidden',
    display: 'none',
    height: '0px',
    width: '0px',
  
  }
}