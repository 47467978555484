import React from 'react'
import ReactDOM from 'react-dom/client'
import './styles/index.css'


import { RouterProvider, createBrowserRouter } from 'react-router-dom'


// Import the layouts
import RootLayout from './layouts/root'

// Import the components
import HomePage from './pages/home.jsx'
import SignInPage from './pages/signin.jsx'
import SignUpPage from './pages/signup.jsx'
import ToSPage from './pages/tos.jsx'
import PrivacyPage from './pages/privacy.jsx'

const router = createBrowserRouter([
  {
    element: <RootLayout />,
    children: [
      { path: "/", element: <HomePage /> },
      { path: "/sign-in", element: <SignInPage /> },
      { path: "/sign-up", element: <SignUpPage /> },
      { path: "/signup", element: <SignUpPage /> },
      { path: "/signin", element: <SignInPage /> },
      { path: "/privacy", element: <PrivacyPage /> },
      { path: "/tos", element: <ToSPage /> },
    ]
  }
])

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
)
