import disentLogo from '../assets/disent.svg'

import { Link, Outlet, useNavigate } from 'react-router-dom'
import { ClerkProvider} from '@clerk/clerk-react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'bootstrap/dist/css/bootstrap.min.css';


const LOGO_SCALE = 2.4
const PUBLISHABLE_KEY = import.meta.env.VITE_CLERK_PUBLISHABLE_KEY

if (!PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key")
}

export default function RootLayout() {
  const navigate = useNavigate();

  return (
    <ClerkProvider
      routerPush={(to) => navigate(to)}
      routerReplace={(to) => navigate(to, { replace: true })}
      publishableKey={PUBLISHABLE_KEY}
    >
      <header className="header">
        <Container fluid>
            <Row xxl={12}>
                <div className='disent-logo-container' style={{ padding: '44px', textAlign:'left', height: '150px'}}>
                    <Link to="/">
                        <img src={disentLogo} className="disent-logo" alt="Disent logo" style={{ width:`${53*LOGO_SCALE}px`, height:`${17*LOGO_SCALE}px`}}/>
                    </Link>
                </div>
            </Row>
        </Container>
      </header>
      <main>
        <Outlet />
      </main>
      {/* <footer>© 2024 Disent, Inc.</footer> */}
    </ClerkProvider>
  )
}