export const elements = {
    card: {
        padding:'2px !important',
        maxHeight:'fit-content !important'
    },
    rootBox: {
        width:'fit-content !important'
    },

    cardBox: {
        boxShadow:'none !important',
        width:'fit-content !important'
    },
    header: {
        visibility: 'hidden',
        height: '0px',
        width: '0px'
    },
    footer: {
        visibility: 'hidden',
        height:'0px',
        width: '0px',
    },
    main: {
        height:'100%'
    },
  
    socialButtonsProviderIcon: {
        height:'39px',
        width: '39px'
    },
  
    avatarBox: {
    height:'39px',
    width: '39px'
  },
  
  dividerRow: {
    visibility:'hidden',
    height: '0px',
    width: '0px',
  }, 
  form: {
    visibility:'hidden',
    height: '0px',
    width: '0px'
  },
  socialButtonsIconButton: {
  boxShadow:'none !important',
  padding: '0px 6px',
  "&:hover":{    
    backgroundColor: 'transparent !important',
    scale:'1.1'
}
  },

}